import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlickScreenSlider = (props: any) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Slider {...settings}>
            <img src="https://picsum.photos/200/300?random=1" alt="" />
            <img src="https://picsum.photos/200/300?random=2" alt="" />
            <img src="https://picsum.photos/200/300?random=3" alt="" />
            <img src="https://picsum.photos/200/300?random=4" alt="" />
        </Slider>
    );
};

export default SlickScreenSlider;
