import React from "react";

type Props = {
    label: string;
    description?: string;
};

const Title: React.FC<Props> = ({ label, description }) => {
    return (
        <div className="title-container">
            <div className="title">
                <h1>{label}</h1>
                <p>{description && description}</p>
            </div>
        </div>
    );
};

export default Title;
