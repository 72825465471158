import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

type Props = RouteComponentProps<{}> & {
  type: string
  extraClass?: string
  children: any
  size?: string
}

const Button: React.FC<Props> = ({ type, children, size, extraClass }) => {
  return <button className={`btn ${type} ${size && size} ${extraClass}`}>{children}</button>
}

export default withRouter(Button)
