import React from "react";
import Input from "../Input";
import Button from "../Button";
import Textarea from "../Textarea";
import Checkbox from "../Checkbox";
import { Translation } from "react-i18next";
import {withFormik} from "formik";
import Yup from '../../../app/config/yup';
import i18n from '../../../app/config/i18n';

const Form: React.FC<{}> = (props : any) => {

    let handleCheckboxChange = (targetId: string, isChecked: boolean) => {
        props.setFieldValue(targetId, isChecked);
    };

    return (
        <Translation>
            {
                (t, {i18n}) => (
                    <form onSubmit={props.handleSubmit} className="form container">
                        <div className="form-control">
                            <div className="form-control-main">
                                <Input type="text"
                                       id={"company_name"}
                                       defaultValue={props.values.company_name}
                                       placeholder={t('common.form.placeholders.company_name')}
                                       error={props.touched.company_name && props.errors.company_name}
                                       onChange={props.handleChange}
                                />
                                <Input type="text"
                                       id={"contact_person"}
                                       defaultValue={props.values.contact_person}
                                       placeholder={t('common.form.placeholders.contact_person')}
                                       error={props.touched.contact_person && props.errors.contact_person}
                                       onChange={props.handleChange}
                                />
                                <Input
                                    type="text"
                                    id={"contact_phone"}
                                    defaultValue={props.values.contact_phone}
                                    placeholder={t('common.form.placeholders.phone')}
                                    error={props.touched.contact_phone && props.errors.contact_phone}
                                    onChange={props.handleChange}
                                />

                                <Textarea
                                    id={"message"}
                                    defaultValue={props.values.message}
                                    placeholder={t('common.form.placeholders.message')}
                                    error={props.touched.message && props.errors.message}
                                    onChange={props.handleChange}
                                />
                            </div>
                            <div className="form-control-aside">
                                <h3>{t('sections.get_it_now.additional_options')}</h3>
                                <Checkbox
                                    id={"number_of_mechanics"}
                                    label={t('common.form.labels.num_of_mechanics')}
                                    isCheckedDefault={props.values.number_of_mechanics}
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    id={"custom_design"}
                                    label={t('common.form.labels.custom_design')}
                                    isCheckedDefault={props.values.custom_design}
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    id={"lower_fee_per_car"}
                                    label={t('common.form.labels.lower_fee_per_car')}
                                    isCheckedDefault={props.values.lower_fee_per_car}
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    id={"premium_contract"}
                                    label={t('common.form.labels.premium_contract')}
                                    isCheckedDefault={props.values.premium_contract}
                                    onChange={handleCheckboxChange}
                                />

                                <p className="form-control-aside__monthly_cost">Monthly cost: <strong>250 CHF</strong>.</p>
                            </div>
                        </div>
                        <div className="form-action">
                            <Button type="tertiary" size="lg">
                                {t('common.form.labels.request_now')}
                            </Button>
                        </div>
                    </form>
                )
            }
        </Translation>
    );
};

const FormWithFormik = withFormik({
    enableReinitialize: true,

    mapPropsToValues() {
        return {
            company_name: '',
            contact_person: '',
            contact_phone: '',
            message: '',
            number_of_mechanics: false,
            custom_design: false,
            lower_fee_per_car: false,
            premium_contract: false
       }
    },

    validationSchema: () => {
        const ts = i18n.getResource(i18n.language, 'translations', 'validations.messages');

        return Yup.object().shape({
            company_name: Yup.string()
                .required(ts.company_name_required),

            contact_person: Yup.string()
                .required(ts.contact_person_required),

            contact_phone: Yup.string()
                .required(ts.phone_required),

            message: Yup.string()
                .required(ts.message_required),

        });
    },

    handleSubmit: (values : any, formikBag : any) => {
        // TODO: Send values
        console.log('form submitted');
    }

})(Form);

export default FormWithFormik;
