import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "../functional/Form/";
import Title from "./Title";
import {Translation} from "react-i18next";

const GetItNow: React.FC = () => {
    const recaptchaRef = React.createRef<any>();
    const siteKey: string = process.env.REACT_RECAPTCHA_KEY ? process.env.REACT_RECAPTCHA_KEY : 'xxx';

    const executeRecaptcha = () => {
        const node = recaptchaRef.current;
        if (node) {
            node.execute();
        }
    };

    return (
        <Translation>
            {
                (t, {i18n}) => (
                    <div className="getitnow-container" id="contact">
                        <Title
                            label={t('sections.get_it_now.caption')}
                            description={t('sections.get_it_now.description')}
                        />
                        <Form />
                    </div>
                )
            }
        </Translation>

    );
};

export default GetItNow;
