import React from 'react';
import Input from "../functional/Input/";
import {Translation} from "react-i18next";
import {withFormik} from "formik";
import Yup from "../../app/config/yup";
import i18n from "../../app/config/i18n";

const Subscribe = (props : any) => {
    return (
        <Translation>
            {
                (t, { i18n }) => (
                    <div className="subscribe-container">
                        <div className="container subscribe">
                            <div className="subscribe__form">
                                <h2>{t('sections.subscribe.caption')}</h2>
                                <p>{t('sections.subscribe.description')}</p>
                                <form  onSubmit={props.handleSubmit}>
                                <Input
                                    id={"email"}
                                    type="text"
                                    defaultValue={props.values.email}
                                    placeholder={t('common.form.placeholders.enter_email')}
                                    error={props.touched.email && props.errors.email}
                                    onChange={props.handleChange}
                                    withButton
                                />
                                </form>
                            </div>
                        </div>
                    </div>
                )
            }
        </Translation>
    );
};

const SubscribeWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => {
        return {
            email: ''
        }
    },
    validationSchema: () => {
        const ts = i18n.getResource(i18n.language, 'translations', 'validations.messages');

        return Yup.object().shape({
            email: Yup.string()
                .required(ts.email_required),
        });
    },
    handleSubmit: (values: any, formikBag: any) => {
        // TODO: Send values
        console.log('form submitted');
    }
})(Subscribe);

export default SubscribeWithFormik;
