import React from "react";

type Props = {
    imgPath: string;
    title: string;
    description: string;
};

const Step: React.FC<Props> = ({ imgPath, title, description }) => {
    return (
        <div className="step">
            <div className="step__title">
                <img src={imgPath} alt=""/>
                <h3>{title}</h3>
            </div>
            <div className="step__description">
                <p>{description}</p>
            </div>
        </div>
    )
};

export default Step;
