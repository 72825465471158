import React, { useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Button from "../Button";

type Props = RouteComponentProps<{}> & {
    id: string;
    placeholder?: string;
    defaultValue?: string;
    error?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Input: React.FC<Props> = ({ id, defaultValue = '', placeholder, error, onChange }) => {
    const [ value, setValue ] = useState(defaultValue);
    const ref = useRef<HTMLTextAreaElement>(null);

    let handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.currentTarget.value);
    };

    return (
        <>
            <textarea
                ref={ref}
                name={id}
                id={id}
                cols={30}
                rows={3}
                placeholder={placeholder}
                onChange={e => {
                    handleChange(e);
                    if (onChange) {
                        onChange(e);
                    }
                }}
                value={value}
            >
            </textarea>
            {error &&
                <div className="input-error">{error}</div>
            }
        </>
    );
};

export default withRouter(Input);
