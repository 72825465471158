import React from 'react';
import { Translation } from 'react-i18next';

const Features = () => {
    return (
        <Translation>
            {
                (t, {i18n}) => (
                    <div className="features-container" id="about">
                        <div className="features container">
                            <div className="feature">
                                <img src="https://icon-library.net/images/save-time-icon/save-time-icon-10.jpg" alt="" className="feature__icon"/>
                                <h3>{t('sections.features.save_time.caption')}</h3>
                                <p>{t('sections.features.save_time.description')}</p>
                            </div>
                            <div className="feature">
                                <img src="https://toppng.com/uploads/preview/easy-snap-icon-free-vector-11562942199aisjzbjqf5.png" alt="" className="feature__icon"/>
                                <h3>{t('sections.features.easy_to_use.caption')}</h3>
                                <p>{t('sections.features.easy_to_use.description')}</p>
                            </div>
                            <div className="feature">
                                <img src="https://cdn4.iconfinder.com/data/icons/business-and-office-1-10/48/38-512.png" alt="" className="feature__icon"/>
                                <h3>{t('sections.features.transparent.caption')}</h3>
                                <p>{t('sections.features.transparent.description')}</p>
                            </div>
                        </div>
                    </div>
                )
            }
        </Translation>
    );
};

export default Features;
