import React from 'react'
import Button from '../functional/Button'
import { Translation } from 'react-i18next'
import MainMenu from './MainMenu'
import imgHeader from '../../assets/images/header.png'

const Header = (props: any) => {
  return (
    <Translation>
      {(t, { i18n }) => (
        <div className="header-container" id="home">
          <MainMenu />
          <div className="header container">
            <div className="header-main">
              <div className="header-main__text">
                <h1>{t('sections.header.caption')}</h1>
                <p>{t('sections.header.description')}</p>
                <div className="header-main__buttons">
                  <Button type="primary">{t('common.form.labels.i_want_it')}</Button>
                  <Button type="secondary">{t('common.form.labels.learn_more')}</Button>
                </div>
              </div>
              <div className="header-main__picture-holder">
                <img src={imgHeader} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  )
}

export default Header
