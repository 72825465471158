import React from 'react'
import Title from './Title'
import Step from './Step'
import { Translation } from 'react-i18next'
import SlickScreenSlider from '../functional/Slider'
import imgAvatar from '../../assets/images/icon-avatar.jpg'
import imgIphoneWrapper from '../../assets/images/iphone-wrapper.png'

const HowItWorks: React.FC = () => {
  return (
    <Translation>
      {(t, { i18n }) => (
        <div className="how-it-works-container" id="packages">
          <Title label={t('sections.how_it_works.caption')} description={t('sections.how_it_works.description')} />
          <div className="how-it-works container">
            <div className="how-it-works__steps">
              <Step
                imgPath={imgAvatar}
                title={t('sections.how_it_works.section_1.caption')}
                description={t('sections.how_it_works.section_1.description')}
              />
              <Step
                imgPath={imgAvatar}
                title={t('sections.how_it_works.section_2.caption')}
                description={t('sections.how_it_works.section_2.description')}
              />
              <Step
                imgPath={imgAvatar}
                title={t('sections.how_it_works.section_3.caption')}
                description={t('sections.how_it_works.section_3.description')}
              />
              <Step
                imgPath={imgAvatar}
                title={t('sections.how_it_works.section_4.caption')}
                description={t('sections.how_it_works.section_4.description')}
              />
            </div>
            <div className="how-it-works__iphone-holder">
              <div className="iphone-container">
                <img src={imgIphoneWrapper} alt="" />
                <SlickScreenSlider />
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  )
}

export default HowItWorks
