import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.tsx'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import { I18nextProvider } from 'react-i18next'
import i18n from './app/config/i18n'
import { Router } from 'react-router'
import history from './history'
import TagManager from 'react-gtm-module'

const root = document.getElementById('rootLanding')

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
})
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
})

if (root.hasChildNodes()) {
  ReactDOM.hydrate(
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <App />
      </Router>
    </I18nextProvider>,
    root,
  )
} else {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <App />
      </Router>
    </I18nextProvider>,
    root,
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
