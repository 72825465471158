import React, {useState} from "react";
import { RouteComponentProps, withRouter } from "react-router";
import "./index.scss";

type Props = RouteComponentProps<{}> & {
    id: string;
    isCheckedDefault?: boolean;
    label: string;
    onChange?: (targetId: string, isChecked: boolean) => void
};

const Checkbox: React.FC<Props> = ({ id, label, isCheckedDefault, onChange }) => {
    const [ checked, setChecked ] = useState(isCheckedDefault || false);

    let handleChange = (e: any) => {
        setChecked(e.target.checked);
        if (onChange) {
            onChange(e.target.id, e.target.checked);
        }
    };

    return (
        <label className="cb-container">
            <span className="label-text">{label}</span>
            <input type="checkbox" id={id} checked={checked} onChange={handleChange} />
            <span className="checkmark"></span>
        </label>
    );
};

export default withRouter(Checkbox);
