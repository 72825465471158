import React, { useRef, useState, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import Button from '../Button'
import { Translation } from 'react-i18next'

type Props = RouteComponentProps<{}> & {
  id: string
  type: string
  placeholder?: string
  withButton?: boolean
  defaultValue?: string
  error?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Input: React.FC<Props> = ({ id, type, defaultValue = '', placeholder, withButton, error, onChange }) => {
  const [value, setValue] = useState(defaultValue)
  const ref = useRef<HTMLInputElement>(null)

  const [screenSize, setScreenSize] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', () => setScreenSize(window.innerWidth))
    return () => {
      window.removeEventListener('resize', () => setScreenSize(window.innerWidth))
    }
  }, [])

  let handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }

  let renderLabel = () => {
    const SM_BREAKPOINT = 640
    if (screenSize < SM_BREAKPOINT) {
      return <span className="icon-dm"></span>
    } else {
      return <Translation>{t => t('common.form.labels.send')}</Translation>
    }
  }

  return (
    <>
      <div className="input-container">
        <input
          ref={ref}
          value={value}
          type={type}
          id={id}
          placeholder={placeholder}
          onChange={e => {
            handleChange(e)
            if (onChange) {
              onChange(e)
            }
          }}
        />
        {withButton && (
          <Button type="tertiary" extraClass="with-button">
            {renderLabel()}
          </Button>
        )}
      </div>
      {error && <div className="input-error">{error}</div>}
    </>
  )
}

export default withRouter(Input)
