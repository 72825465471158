import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Meta from './components/functional/Meta'
import Home from './views/Home'
import Header from './components/layout/Header'
import Main from './components/layout/Main'
import Footer from './components/layout/Footer'
import './assets/css/app.css'

const App = () => {
  return (
    <div className="App">
      <Router>
        <Meta>
          <Header />
          <Main>
            <Route path="/" exact={true} component={Home} />
          </Main>
          <Footer />
        </Meta>
      </Router>
    </div>
  )
}

export default App
