import React, { useState } from 'react'
import { Translation } from 'react-i18next'
import history from '../../history'
import classNames from 'classnames'
import imgLogo from '../../assets/images/logo.png'

const MainMenu = () => {
  const [activeId, setActiveId] = useState('#home')

  history.listen(({ hash }) => {
    setActiveId(hash)
  })

  let renderLink = (linkName: string, activeByDefault?: boolean) => {
    return (
      <a
        href={`#${linkName.toLowerCase()}`}
        className={classNames({
          active: `#${linkName.toLowerCase()}` === activeId,
        })}
      >
        {linkName}
      </a>
    )
  }

  return (
    <Translation>
      {(t, { i18n }) => (
        <div className="main-menu-container">
          <div className="main-menu container">
            <div className="main-menu__brand">
              <img src={imgLogo} alt="" />
            </div>
            <div className="main-menu__nav">
              <div>
                {renderLink(t('common.links.home'))}
                {renderLink(t('common.links.about'))}
                {renderLink(t('common.links.packages'))}
                {renderLink(t('common.links.contact'))}
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  )
}

export default MainMenu
