/* eslint-disable */
import React from 'react'

export default {
  translations: {
    validations: {
      messages: {
        company_name_required: 'Company name required',
        contact_person_required: 'Contact person required',
        phone_required: 'Contact phone required',
        message_required: 'Message required',
        enter_email_required: 'Enter your email address required',
        email_required: 'Email is required',
      },
      default: {
        mixed: {
          required: '${label} required',
        },
        string: {
          email: 'Please fill in a valid email',
          min: 'Value too short',
          max: 'Value too long',
          test: 'You have to agree with our ${label}',
        },
        boolean: {
          required: 'You have to agree with our ${label}',
        },
      },
    },
    common: {
      form: {
        placeholders: {
          company_name: 'Company name',
          contact_person: 'Contact person',
          phone: 'Contact phone',
          message: 'Message',
          enter_email: 'Enter your email address',
        },
        labels: {
          num_of_mechanics: '10+ Mechanics',
          custom_design: 'Custom design',
          lower_fee_per_car: 'Lower fee per car',
          premium_contract: 'Premium contract',
          send: 'Send',
          i_want_it: 'I WANT IT',
          learn_more: 'LEARN MORE',
          request_now: 'REQUEST NOW',
        },
      },
      links: {
        home: 'Home',
        about: 'About',
        packages: 'Packages',
        contact: 'Contact',
      },
    },
    sections: {
      header: {
        caption: 'SPEED UP YOUR MECHANICS GET MORE ADDITIONAL COST',
        description:
          'There are many variables of passages of something else available. The manually have suffe stellor by inject outcome.',
      },
      features: {
        save_time: {
          caption: 'Save time',
          description: 'With garage booster do 20 minutes work in just 1 minute',
        },
        easy_to_use: {
          caption: 'Easy to use',
          description: 'With garage booster do 20 minutes work in just 1 minute',
        },
        transparent: {
          caption: 'Transparent',
          description: 'With garage booster do 20 minutes work in just 1 minute',
        },
      },
      how_it_works: {
        caption: 'HOW IT WORKS?',
        description:
          'To control the font size of an element at a specific breakpoint, add a prefix to any existing font size utility. For example, use md:text-lg to apply the text-lg utility at only medium screen sizes and above.',
        section_1: {
          caption: 'GARAGE PROFILE',
          description:
            'Setup your prices for services, automate notifications for user and get more profit. Manage youor mechanics and approve request from mechanic.',
        },
        section_2: {
          caption: 'SEARCH CAR',
          description:
            'Find car brand, model and other details from car by search with licence plate or manually. search plates is available for all Switzerland plates.',
        },
        section_3: {
          caption: 'FIND CAR SERVICE',
          description: 'Find car services by search. Adapt car work and car parts. Get instant prices for services',
        },
        section_4: {
          caption: 'SEND USER SMS + EMAIL',
          description:
            'After enter car data and car services, Garage Booster automatically send sms and/or email to user who need to approve additional cost',
        },
      },
      get_it_now: {
        caption: 'GET IT NOW',
        description:
          'To control the font size of an element at a specific breakpoint, add a prefix to any existing font size utility. For example, use md:text-lg to apply the text-lg utility at only medium screen sizes and above.',
        additional_options: 'Additional options',
      },
      subscribe: {
        caption: 'SUBSCRIBE TO OUR NEWSLETTER',
        description:
          'Subscribe if you want to get all news from our network. Get latest updates, feedback and information about Garage Booster project.',
      },
      footer: {
        copyright: 'Copyright 2019 © Carhelper',
        facebook: 'Facebook',
      },
    },
  },
}
