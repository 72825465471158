import React from 'react';
import GetItNow from '../components/section/GetItNow';
import HowItWorks from '../components/section/HowItWorks';
import Subscribe from "../components/section/Subscribe";
import Features from "../components/section/Features";

const Home = () => {
    return (
        <>
            <Features />
            <HowItWorks />
            <GetItNow />
            <Subscribe />
        </>
    );
};

export default Home;
