import React from "react";
import {RouteComponentProps, withRouter} from "react-router";

type PathParamsType = {
    param1: string,
}

type Props = RouteComponentProps<PathParamsType> & {
    location: string,
    children: object,
}

const Meta = (props: Props) => {
    return (
        <>
            {props.children}
        </>
    );
};

export default withRouter(Meta);
