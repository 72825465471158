import React from 'react';
import {Translation} from "react-i18next";

const Footer = () => {
    return (
        <Translation>
            {
                (t, { i18n }) => (
                    <div className="footer-container">
                        <div className="footer container flex">
                            <div className="footer__copyright">
                                <p>{t('sections.footer.copyright')}</p>
                            </div>
                            <div className="footer__menu">
                                <p>
                                    <a href="#">{t('sections.footer.facebook')}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
        </Translation>
    );
};

export default Footer;
